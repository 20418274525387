import axios from "axios";
import {creds} from "@/states/Cred";

export default class TemplateService {

    createTemplate(templateDto) {
        return axios.post(`${process.env.VUE_APP_API_URL}/templates`,
            templateDto,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response)
    }

    updateTemplate(templateDto) {
        return axios.put(`${process.env.VUE_APP_API_URL}/templates`,
            templateDto,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response)
    }

    deleteTemplate(templateDto) {
        return axios.delete(`${process.env.VUE_APP_API_URL}/templates/${templateDto._id}`,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response)
    }

    getAllTemplates() {
        return axios.get(`${process.env.VUE_APP_API_URL}/templates/`,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res.data)
            .catch(err => err.response)
    }

    getTemplatesByAccount(accountId) {
        return axios.get(`${process.env.VUE_APP_API_URL}/templates/account/${accountId}`,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response)
    }
}
