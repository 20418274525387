<template>
  <Toast/>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>{{ lastSearchCriteria.account.accountName }} Account Templates</h5>
        <Toolbar class="mb-4">
          <template #start>
            <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>
            <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="deleteSelected"
                    :disabled="!selectedRows || !selectedRows.length"/>
          </template>

        </Toolbar>
        <DataTable :value="templates" edit-mode="row" v-model:editingRows="editingRows" @row-edit-save="onRowEditSave"
                   v-model:selection="selectedRows" dataKey="_id" responsiveLayout="scroll">
          <Column selectionMode="multiple" style="width:5%"></Column>
          <Column field="templateName" header="Name" sortable style="width:15%">
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" autofocus/>
            </template>
          </Column>

          <Column field="templateContent" header="Content" sortable style="width:60%">
            <template #editor="{ data, field }">
              <Textarea type="text" v-model="data[field]" :autoResize="false" rows="3" cols="120"/>
            </template>
          </Column>
          <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>
        </DataTable>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="newTemplateDialog" :style="{width: '450px'}" header="Create New Template" :modal="true"
          class="p-fluid">
    <div class="col-12 md:col-8">
      <div class="field">
        <label for="templateName">Template Name</label>
        <InputText id="templateName" type="text" v-model="templateName"
                   aria-describedby="templateNameHelp"></InputText>
        <small id="templateNameHelp">Custom template name, anything you want like. TEMPLATE_1</small>
      </div>
    </div>
    <div class="col-12 md:col-12">
      <div class="field">
        <label for="templateContent">Template Content</label>
        <Textarea v-model="templateContent" rows="5" cols="30"
                  aria-describedby="templateContentHelp"></Textarea>
        <small id="templateContentHelp">Template Content Should contain answer for reviews.</small>
      </div>
    </div>

    <template #footer>
      <Button icon="pi pi-check" label="Create" @click="createTemplate()"/>

      <Button icon="pi pi-times" label="Cancel" class="p-button-secondary" style="margin-left: .5em"
              @click="cancelCreate()"/>
    </template>
  </Dialog>
</template>

<script>
import TemplateService from "@/service/TemplateService";
import {lastSearchCriteria} from "@/states/State";

export default {
  name: "Templates",
  templateService: null,

  data() {
    return {
      lastSearchCriteria,
      templates: null,
      editingRows: [],
      selectedRows: [],
      newTemplateDialog: false,
      templateName: null,
      templateContent: null
    }
  },
  methods: {

    onRowEditSave(event) {
      let {newData, index} = event;
      this.templates[index] = newData;
      this.templateService.updateTemplate(newData)
          .then(res => {
            if (res.status !== 200) {
              this.$toast.add({
                severity: 'error',
                summary: 'Template Update Failed',
                detail: res.status + " " + res.data.detail,
                life: 3000
              });
            } else {
              this.$toast.add({
                severity: 'success',
                summary: 'Template Updated',
                life: 3000
              });
            }
          })
    },

    refreshTemplates: function () {
      this.templateService.getTemplatesByAccount(lastSearchCriteria.account._id)
          .then(res => {
            if (res.status !== 200) {
              this.$toast.add({
                severity: 'error',
                summary: 'Error Occurred while Fetching templates',
                detail: res.status + " " + res.data.detail,
                life: 3000
              });
            } else {
              this.templates = res.data
            }
          })
    },

    deleteSelected() {
      this.selectedRows.forEach((row) => this.templateService.deleteTemplate(row)
          .then(res => {
            if (res.status !== 200) {
              this.$toast.add({
                severity: 'error',
                summary: 'Template Delete Failed',
                detail: res.status + " " + res.data.detail,
                life: 3000
              });
            }
          }))
      this.refreshTemplates()
    },

    openNew() {
      this.newTemplateDialog = true;
    },

    createTemplate() {
      const createTemplateDto = {
        accountId: lastSearchCriteria.account._id,
        templateName: this.templateName,
        templateContent: this.templateContent
      }


      this.templateService.createTemplate(createTemplateDto)
          .then(res => {
            if (res.status !== 200) {
              this.$toast.add({
                severity: 'error',
                summary: 'Template Creation Failed',
                detail: res.status + " " + res.data.detail,
                life: 3000
              });
            } else {
              this.$toast.add({
                severity: 'success',
                summary: 'Template Created',
                life: 3000
              });

              this.newTemplateDialog = false;
              this.templateName = null;
              this.templateContent = null;

            }
          }).then(() => this.refreshTemplates())
    },

    cancelCreate() {
      this.newTemplateDialog = false;
    }
  },

  watch: {
    'lastSearchCriteria.account': function () {
      this.refreshTemplates()
    },

  },

  emits: ['acall'],
  created() {
    this.templateService = new TemplateService();
  },

  mounted() {
    this.refreshTemplates()
  }
}
</script>

<style scoped>

</style>
